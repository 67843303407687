<template>
  <ProductListSlider
    v-if="(hasAcceptProfilation && isPreferredSlider) || !isPreferredSlider"
    :config="config"
    :products="products"
    :categoryBlockName="'CrmProductSlider'"
    v-intersect.once="handleView"
    @selectProduct="handleClick"
    class="product-card-horizontal-mobile"
  />
</template>
<style lang="scss">
.show-more {
  .v-btn {
    margin-right: -4px;
  }
}
.product-slider {
  .proposal-img {
    margin-bottom: 10px;
  }
  .banner-card {
    width: 100%;
    margin-bottom: 20px;
  }
  .proposal-card {
    width: 220px;
    height: 380px;
    .v-card__text {
      width: auto;
    }
    img {
      padding: 0px;
      align-self: center;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .product-card {
      .v-image {
        min-height: 110px;
      }
    }
    .proposal-card {
      height: 285px;
      width: 170px;
      .v-card__text {
        font-size: 11px;
        padding: 0 5px;
      }
    }
  }
  .firstCard {
    float: left;
  }
}
</style>
<script>
// import ProductCard from "@/components/product/ProductCard.vue";
import ProductListSlider from "./elements/ProductListSlider.vue";

// import categoryBlockType from "./categoryBlockType";
import deliveryReactive from "~/mixins/deliveryReactive";

import ProductService from "~/service/productService";
import analyticsService from "@/commons/service/analyticsService";

import { mapGetters } from "vuex";
import cartInfo from "~/mixins/cartInfo";

import Vue from "vue";
import category from "@/commons/mixins/category";

export default {
  name: "CrmProductListSlider",
  props: {
    config: {
      type: Object,
      required: true
    },
    container: {
      type: Boolean,
      default: true
    },
    productId: {
      type: Number,
      required: false
    },
    categoryId: {
      type: Number,
      required: false
    }
  },
  mixins: [
    deliveryReactive,
    cartInfo,
    category
    //  categoryBlockType
  ],
  components: {
    // ProductCard,
    ProductListSlider
  },
  data() {
    return {
      products: [],
      categoryBlockName: this.$options.name,
      swiperDefaultOption: {
        slidesPerGroup: 1,
        spaceBetween: 20,
        watchOverflow: true,
        autoplay: false,
        pagination: {
          el: `#crm-list-pagination-${this.config.categoryBlockId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#crm-list-slider-prev-${this.config.categoryBlockId}`,
          nextEl: `#crm-list-slider-next-${this.config.categoryBlockId}`
        },
        breakpoints: {
          0: {
            slidesPerView: 1
          },
          600: {
            slidesPerView: 2
          },
          960: {
            slidesPerView: 3
          },
          1264: {
            slidesPerView: 4
          },
          1900: {
            slidesPerView: 6
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    footerText() {
      return this.$ebsn.meta(
        this.category,
        "metaData.category_info.FOOTER_TEXT",
        null
      );
    },
    hasAcceptProfilation() {
      let crmSegment = this.getCrmSegmentId(4502);
      if (crmSegment) return crmSegment.value === "1";
      else return false;
    },
    isPreferredSlider() {
      return (
        Vue.$ebsn.meta(
          this.config,
          "categoryblocktype_CrmProductSlider.ALGORITHM"
        ) === "29"
      );
    },
    promoAppAlgorithm() {
      const algorithm = Vue.$ebsn.meta(
        this.config,
        "categoryblocktype_CrmProductSlider.ALGORITHM"
      );
      return algorithm === "25" || algorithm === "27";
    }
  },
  methods: {
    async reload() {
      this.products = [];

      let algorithm = Vue.$ebsn.meta(
        this.config,
        "categoryblocktype_CrmProductSlider.ALGORITHM"
      );

      if (algorithm) {
        let params = {
          layout: algorithm,
          limit: Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_CrmProductSlider.PRODUCT_LIMIT",
            12
          ),

          shuffle: Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_CrmProductSlider.PRODUCT_RANDOM",
            false
          ),
          promo: Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_CrmProductSlider.PROMO",
            false
          )
        };
        // //Add categoryID for adv of 25
        if (this.promoAppAlgorithm) {
          params.category_id = this.category.categoryId;
        }
        if (this.productId) params.product_id = this.productId;
        if (this.categoryId) params.category_id = this.categoryId;

        let response = await ProductService.adv(params);
        if (response.products) {
          this.products = response.products;
        }
        if (!this.products || this.products.length < 1) {
          this.$emit("hide");
        }
        // this.$emit("loaded", this.products ? this.products.length : 0);
      }
    },
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting) {
        analyticsService.viewProducts(this.products, this.getName());
      }
    },

    handleClick(payload) {
      analyticsService.clickProduct(
        payload.product,
        this.getName(),
        payload.index
      );
    }
  },
  mounted() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.reload();
  }
};
</script>
