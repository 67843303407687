import dayjs from "dayjs";
import axios from "~/plugins/axios";
import { defaultParams } from "./defaultParams";
import Qs from "qs";

export default {
  paramsSerializer: params => {
    return Qs.stringify(params, { arrayFormat: "brackets" });
  },
  getMenuByLocation(location) {
    let url = `/p/wp-json/wp-api-menus/v2/menu-locations/${location}`;
    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(err => err);
  },
  getSlider(name, date, warehouseId, deliveryServiceId) {
    let roundedDate = date
      .startOf("day")
      .add(12, "h")
      .unix();
    let url = `/p/wp-json/wp/v2/new_slides/${name}/${roundedDate}/${warehouseId}`;
    return axios.get(url, {
      params: {
        deliveryServiceId: deliveryServiceId
      }
    });
  },
  getPage(slug) {
    return axios
      .get("/p/wp-json/wp/v2/pages", {
        params: { slug: slug },
        paramsSerializer: this.paramsSerializer
      })
      .then(response => response.data)
      .catch(err => err);
  },
  // getCustomPostByFilters(customPostName, filters) {
  //   let url = `/p/wp-json/wp/v2/${customPostName}`;
  //   let params = {};
  //   if (filters) {
  //     filters.forEach(
  //       filter => (params[filter.filterName] = filter.filterValue)
  //     );
  //   }
  //   return axios.get(url, { params: params }).then(response => {
  //     if (response.data) {
  //       return response.data;
  //     }
  //   });
  // },
  getCustomPostByCustomTaxonomyId(
    customPostName,
    taxonomy,
    taxonomyId,
    pageNum = 8
  ) {
    let url = `/p/wp-json/wp/v2/${customPostName}?${taxonomy}=${taxonomyId}&per_page=${pageNum}`;
    return axios.get(url).then(response => {
      if (response.data) {
        return {
          data: response.data,
          page: {
            totItems: parseInt(response.headers["x-wp-total"]),
            totPages: parseInt(response.headers["x-wp-totalpages"])
          }
        };
      }
    });
  },

  getCustomPostByFilters(
    customPostName,
    filters,
    resultsPerPage = 10,
    page = 1
  ) {
    let url = `/p/wp-json/wp/v2/${customPostName}?per_page=${resultsPerPage}`;
    let params = {};
    let today = dayjs().format("DD-MM-YYYY");
    params.hash = defaultParams().hash + "-" + today;
    params.page = page;
    if (filters) {
      filters.forEach(
        filter => (params[filter.filterName] = filter.filterValue)
      );
    }
    return axios
      .get(url, { params: params, paramsSerializer: this.paramsSerializer })
      .then(response => {
        if (response) {
          return {
            data: response.data,
            page: {
              totItems: parseInt(response.headers["x-wp-total"]),
              totPages: parseInt(response.headers["x-wp-totalpages"])
            }
          };
        }
      })
      .catch(err => err);
  },
  getCustomWPEndpoint(endpoint, filters, resultsPerPage = 10, page = 1) {
    let url = `/p/wp-json/custom/v1/${endpoint}?per_page=${resultsPerPage}`;
    let params = {};
    let today = dayjs().format("DD-MM-YYYY");
    params.hash = defaultParams().hash + "-" + today;
    params.page = page;
    if (filters) {
      filters.forEach(
        filter => (params[filter.filterName] = filter.filterValue)
      );
    }
    return axios
      .get(url, { params: params, paramsSerializer: this.paramsSerializer })
      .then(response => {
        if (response) {
          return {
            data: response.data,
            page: {
              totItems: parseInt(response.headers["x-wp-total"]),
              totPages: parseInt(response.headers["x-wp-totalpages"])
            }
          };
        }
      })
      .catch(err => err);
  },
  getCustomPostBySlug(customPostName, slug) {
    let url = `/p/wp-json/wp/v2/${customPostName}`;
    let params = {};
    params.slug = slug;
    let today = dayjs().format("DD-MM-YYYY");
    params.hash = defaultParams().hash + "-" + today;
    return axios
      .get(url, { params, paramsSerializer: this.paramsSerializer })
      .then(response => {
        return response.data;
      });
  },
  getNexPrevPosts(postType, postId, next = true, perPage = 1) {
    const nav = next ? "next" : "previous";
    const order = next ? "asc" : "desc";

    let url = `/p/wp-json/wp/v2/${postType}?${nav}=${postId}&order=${order}&per_page=${perPage}&orderby=date`;
    let params = {};
    let today = dayjs().format("DD-MM-YYYY");
    params.hash = defaultParams().hash + "-" + today;
    return axios
      .get(url, { params, paramsSerializer: this.paramsSerializer })
      .then(response => {
        return response.data;
      });
  },
  getForm(formId) {
    let url = "/p/wp-json/wp/v2/get_mail_pars";
    return axios
      .get(url, {
        params: { form_id: formId },
        paramsSerializer: this.paramsSerializer
      })
      .then(response => {
        return response.data.req.data;
      });
  },
  getUxBlocks(slug) {
    let url = `/p/wp-json/wp/v2/ux-blocks?filter[term]=${slug}&filter[orderby]=menu_order&order=asc`;
    return axios.get(url).then(response => {
      return response.data;
    });
  },
  getUxBlock(slug) {
    let url = `/p/wp-json/wp/v2/ux-blocks?slug=${slug}`;
    return axios.get(url).then(response => {
      return response.data;
    });
  },
  getSliderBySlug(slug) {
    const url = `/p/wp-json/wp/v2/slides_by_id/${slug}`;
    return axios
      .get(url)
      .then(response => response.data)
      .catch(err => err);
  },
  sendForm(data) {
    let url = "/p/wp-admin/admin-ajax.php";
    const formData = new FormData();
    formData.append("security", data.nonce);
    formData.append(
      "formData",
      JSON.stringify({
        id: data.form_id,
        fields: { ...data.fields },
        extra: data.extra,
        settings: data.settings
      })
    );
    formData.append("action", "nf_ajax_submit");
    return axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data" // Ensure proper content type for file upload
        }
      })
      .then(response => {
        global.EventBus.$emit("success", {
          message: response.data.data.actions.success_message
        });
        return response.data.data;
      });
  },
  uploadFile(data, fieldId, files) {
    let url = "/p/wp-admin/admin-ajax.php?action=nf_fu_upload";
    const formData = new FormData();
    formData.append("form_id", data.form_id);
    formData.append("field_id", fieldId);
    formData.append("nonce", data.nonce);
    formData.append("files", files);
    return axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data" // Ensure proper content type for file upload
        }
      })
      .then(response => {
        return response.data.data;
      });
  },

  searchCustom(term) {
    ///p/wp-json/custom/ali/search?q=burro
    return axios
      .get("/p/wp-json/custom/ali/search", {
        params: { q: term },
        paramsSerializer: this.paramsSerializer
      })
      .then(response => response.data)
      .catch(err => err);
  },
  search(term, subtype, page = 1, resultsPerPage = 24) {
    return axios
      .get("/p/wp-json/wp/v2/search", {
        params: {
          search: term,
          page: page,
          per_page: resultsPerPage,
          type: "post",
          subtype: subtype
        },
        paramsSerializer: this.paramsSerializer
      })
      .then(response => {
        return {
          data: response.data,
          page: {
            totItems: parseInt(response.headers["x-wp-total"]),
            totPages: parseInt(response.headers["x-wp-totalpages"])
          }
        };
      })
      .catch(err => err);
  }
};
