<template functional>
  <div class="product-price">
    <div v-if="props.product.priceDisplay" class="price-container">
      <div class="old-price" v-if="props.showOldPrice">
        <span v-if="props.product.priceStandardDisplay">{{
          parent.$n($options.oldPricePerUnit(props.product), "currency")
        }}</span>
      </div>

      <div
        class="price-cnt d-flex  justify-space-between"
        :style="{ flexDirection: props.vertical ? 'column-reverse' : 'row' }"
      >
        <div
          class="cur-price"
          :class="{ 'promo-price': props.product.priceStandardDisplay }"
        >
          {{ parent.$n($options.pricePerUnit(props.product), "currency") }}
          <!-- {{ parent.$n(props.product.priceDisplay, "currency")
          }}<span
            class="small text-lowercase"
            v-if="props.product.productInfos.TIPOLOGIA != 'Pezzo'"
            >/{{ props.product.weightUnitDisplay }}</span
          > -->
        </div>

        <div v-if="props.showDescrWeight" class="product-descr-weight">
          <span
            class="weight-unit text-uppercase"
            v-if="
              props.product.productInfos.TIPOLOGIA == 'Pezzo' &&
                props.product.priceUmDisplay
            "
          >
            {{ parent.$n(props.product.priceUmDisplay, "currency") }}/KG
          </span>
          <span
            class="weight-unit text-uppercase test"
            v-if="
              props.product.productInfos.TIPOLOGIA == 'Sfuso' &&
                props.product.priceDisplay
            "
          >
            {{ parent.$n(props.product.priceDisplay, "currency") }}/{{
              props.product.weightUnitDisplay
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import catalogService from "@/commons/service/catalogService";

export default {
  name: "ProductPrice",
  props: {
    product: {
      type: Object,
      required: true
    },
    showDescrWeight: {
      type: Boolean,
      default: true
    },
    showOldPrice: {
      type: Boolean,
      default: true
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  oldPricePerUnit(product) {
    if (product?.productInfos?.TIPOLOGIA == "Pezzo") {
      return product.priceStandardDisplay;
    } else {
      return (
        product.priceStandardDisplay *
        parseFloat(
          Number(product.productInfos.WEIGHT_SELLING) /
            catalogService.getMultiplier(product)
        )
      );
    }
  },
  pricePerUnit(product) {
    if (product?.productInfos?.TIPOLOGIA == "Pezzo") {
      return product.priceDisplay;
    } else {
      return (
        product.priceDisplay *
        parseFloat(
          Number(product.productInfos.WEIGHT_SELLING) /
            catalogService.getMultiplier(product)
        )
      );
    }
  }
};
</script>
