import CartService from "./cartService";
/* eslint-disable no-debugger */
import axios from "~/plugins/axios";
// import store from "../store";

export default {
  refreshLogin() {
    return CartService.getCart().then(data => {
      if (data && data.user) {
        return true;
      }
    });
  },
  getMfaEnabled() {
    let params = new URLSearchParams();
    let url = "/ebsn/api/registration/mfa_enabled";
    return axios
      .get(url, params)
      .then(data => {
        return data.data.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  updateMfa(enable) {
    let params = new URLSearchParams();
    params.append("enabled", enable);
    let url = "/ebsn/api/registration/update_mfa";
    return axios
      .post(url, params)
      .then(data => {
        return data.data.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  login(username, password, rememberMe, token, mfaPin) {
    let params = new URLSearchParams();
    params.append("login", username.trim());
    params.append("password", password);
    params.append("remember_me", rememberMe);
    params.append("show_sectors", global.config.showSectors);
    if (mfaPin) params.append("mfa_pin", mfaPin);
    if (token) params.append("token", token);
    let url = "/ebsn/api/auth/login";
    return axios
      .post(url, params)
      .then(data => {
        return data.data.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  logout() {
    return axios
      .post("/ebsn/api/auth/logout")
      .then(data => {
        return data.data.data;
      })
      .catch(data => {
        return data.response;
      });
  },
  resendRegistrationEmail(email) {
    let params = new URLSearchParams();
    params.append("email", email);
    return axios
      .get("/ebsn/api/registration/registration_resend_email", {
        params: { e_mail: email }
      })
      .then(data => {
        return data.data.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  getMarketingCloudContactKey(email, maxCount) {
    let emails = [];
    emails.push(email);

    let params = {
      ChannelAddressList: emails,
      MaximumCount: maxCount ? maxCount : 1
    };

    return axios
      .post("/ebsn/api/marketingcloud/contact-key", params)
      .then(data => {
        console.log(data);
        return data.data.data.value;
      })
      .catch(data => {
        console.log(data);
        return data;
      });
  },
  removeMarketingCloudContactKey(contactKey, userId) {
    let params = {
      contactKey: contactKey,
      userId: userId
    };

    return axios
      .post("/ebsn/api/marketingcloud/remove", params)
      .then(data => {
        console.log(data);
        return data;
      })
      .catch(data => {
        console.log(data);
        return data;
      });
  }
};
